'use client'

import React, { useState, useCallback } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/navigation'
import { ArrowRight, Search } from 'lucide-react'
import Link from 'next/link'

const popularProjects = [
  { name: 'Renovare', href: '/servicii/renovari-interioare' },
  { name: 'Construcție', href: '/servicii/constructii' },
  { name: 'Design Interior', href: '/servicii/finisaje' },
  { name: 'Grădinărit', href: '/servicii/exterioare/gradinarit' }
]

export default function Hero() {
  const router = useRouter()
  const [selectedTrade, setSelectedTrade] = useState('')

  const handleTradeSelect = useCallback((e) => {
    setSelectedTrade(e.target.value)
  }, [])

  const handleNext = useCallback(() => {
    if (selectedTrade) {
      router.push(`/new-post?trade=${encodeURIComponent(selectedTrade)}`)
    }
  }, [selectedTrade, router])

  return (
    <section className="relative overflow-hidden text-white min-h-screen flex items-center justify-center">
      <div className="absolute inset-0">
        <picture className="w-full h-full">
          {/* <source media="(min-width: 1024px)" srcSet="/ceva.jpeg" /> */}
          {/* <source media="(max-width: 1023px)" srcSet="/mobHero.png" /> */}
          <Image
            src="/ceva.jpeg"
            alt="Hero background"
            priority
            fill
            className="object-cover w-full h-full"
            sizes="(min-width: 1024px) 100vw,
                   (max-width: 1023px) 100vw"
            quality={90}
          />
        </picture>
        <div className="absolute inset-0 bg-gradient-to-r from-black/80 via-black/50 to-black/30" />
        <div className="absolute inset-0 bg-black/20" />
      </div>

      <div className="relative z-10 container mx-auto px-4 flex flex-col justify-center min-h-screen py-12">
        <div className="flex-grow flex items-center">
          <div className="w-full space-y-8 max-w-4xl mx-auto">
            <div className="flex items-center justify-center gap-2">
              <div className="w-2 h-2 rounded-full bg-[#f0ff5a]"></div>
              <span className="text-sm font-medium text-[#f0ff5a]">
                Platforma Meșterilor Profesioniști
              </span>
            </div>

            <h1 className="text-4xl font-bold text-white text-center">
              Descoperă Meseriașii pentru casa ta
            </h1>

            <p className="text-lg text-gray-100 max-w-2xl text-center mx-auto">
              Conectăm talente excepționale cu proiecte extraordinare. 
              Transformă-ți ideile în realitate!
            </p>

            <div className="bg-white/10 backdrop-blur-md p-4 rounded-lg w-full sm:w-[70%] mx-auto">
              <div className="flex flex-col sm:flex-row gap-4">
                <div className="relative flex-1">
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Ex: Instalator, Zugrav"
                    className="w-full pl-10 py-3 bg-white text-black placeholder-black rounded-md focus:ring-2 focus:ring-[#f0ff5a] focus:outline-none"
                    onChange={handleTradeSelect}
                  />
                </div>
                <button
                  onClick={handleNext}
                  className="py-3 px-6 bg-[#f0ff5a] hover:bg-[#e6ff2b] text-gray-900 rounded-md flex items-center justify-center whitespace-nowrap"
                >
                  Continuă <ArrowRight className="ml-2 w-5 h-5" />
                </button>
              </div>
            </div>

            <div>
              <p className="mb-3 font-medium text-white text-center">Proiecte Populare:</p>
              <div className="flex flex-wrap justify-center gap-3">
                {popularProjects.map((project, index) => (
                  <Link 
                    key={index}
                    href={project.href}
                    className="px-4 py-2 bg-white bg-opacity-20 hover:bg-opacity-30 text-white border border-white border-opacity-30 rounded-md"
                  >
                    {project.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

