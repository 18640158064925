'use client'

import { useState } from 'react'
import Image from 'next/image'
import { motion, AnimatePresence } from 'framer-motion'
import { ChevronLeft, ChevronRight, CheckCircle, ArrowRight } from 'lucide-react'
import Link from 'next/link'

const features = [
  {
    id: 1,
    title: "Gestionează-ți Afacerea",
    description: "Accesează un dashboard intuitiv pentru a-ți gestiona proiectele, clienții și finanțele cu ușurință.",
    image: "/images/dashboard-desktop.png",
    benefits: ["Vizualizare completă a proiectelor", "Gestionare eficientă a timpului", "Rapoarte financiare detaliate"]
  },
  {
    id: 2,
    title: "Găsește Oportunități",
    description: "Conectează-te cu clienți potențiali și accesează o piață mai largă pentru serviciile tale.",
    image: "/images/opportunity.png",
    benefits: ["Acces la cereri de ofertă", "Sistem de rating și recenzii", "Promovare targetată a serviciilor"]
  },
  {
    id: 3,
    title: "Crește-ți Echipa",
    description: "Recrutează și gestionează ușor angajați sau subcontractori pentru proiectele tale.",
    image: "/images/opportunity.png",
    benefits: ["Instrumente de recrutare", "Gestionarea programului echipei", "Evaluări de performanță"]
  }
]

export default function EntrepreneurFeatures() {
  const [activeFeature, setActiveFeature] = useState(0)

  const nextFeature = () => {
    setActiveFeature((prev) => (prev + 1) % features.length)
  }

  const prevFeature = () => {
    setActiveFeature((prev) => (prev - 1 + features.length) % features.length)
  }

  return (
    <section className="py-12 sm:py-24 bg-gradient-to-br from-white via-gray-50 to-white overflow-hidden">
      <div className="container px-4 mx-auto">
        <div className="max-w-6xl mx-auto">
          {/* Header */}
          <div className="text-center mb-12">
            <div className="flex items-center justify-center gap-2 mb-4">
              <div className="w-2 h-2 rounded-full bg-gray-900"></div>
              <span className="text-sm font-medium text-gray-900">
                Devino PRO Meseriaș
              </span>
            </div>
            <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-4">
              Transformă-ți Meseria {' '}
              <span className="text-gray-900">în Afacere</span>
            </h2>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto">
              Transformă-ți meseria într-o afacere de succes cu instrumentele și suportul nostru dedicat.
            </p>
          </div>

          {/* Feature Slider */}
          <motion.div 
            className="rounded-2xl shadow-lg bg-white overflow-hidden"
          >
            <div className="p-8 lg:p-12">
              <AnimatePresence mode="wait">
                <motion.div
                  key={activeFeature}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  className="flex flex-col lg:flex-row items-center gap-12"
                >
                  <div className="w-full lg:w-1/2">
                    <h3 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-6">
                      {features[activeFeature].title}
                    </h3>
                    <p className="text-lg text-gray-600 mb-8">
                      {features[activeFeature].description}
                    </p>
                    <ul className="space-y-4 mb-8">
                      {features[activeFeature].benefits.map((benefit, index) => (
                        <li key={index} className="flex items-center text-gray-700">
                          <CheckCircle className="w-5 h-5 text-gray-900 mr-3 flex-shrink-0" />
                          <span>{benefit}</span>
                        </li>
                      ))}
                    </ul>
                    
                    <div className="flex items-center gap-6">
                      <button 
                        onClick={prevFeature}
                        className="p-2 text-gray-400 hover:text-gray-900 transition-colors"
                        aria-label="Anterior"
                      >
                        <ChevronLeft className="w-6 h-6" />
                      </button>
                      <div className="flex gap-3">
                        {features.map((_, index) => (
                          <button
                            key={index}
                            onClick={() => setActiveFeature(index)}
                            className={`w-2.5 h-2.5 rounded-full transition-colors ${
                              activeFeature === index ? 'bg-gray-900' : 'bg-gray-200 hover:bg-gray-300'
                            }`}
                            aria-label={`Slide ${index + 1}`}
                          />
                        ))}
                      </div>
                      <button 
                        onClick={nextFeature}
                        className="p-2 text-gray-400 hover:text-gray-900 transition-colors"
                        aria-label="Următor"
                      >
                        <ChevronRight className="w-6 h-6" />
                      </button>
                    </div>
                  </div>

                  <div className="w-full lg:w-1/2">
                    <div className="relative aspect-[4/3] w-full">
                      <Image
                        src={features[activeFeature].image}
                        alt={features[activeFeature].title}
                        fill
                        className="object-contain"
                        sizes="(max-width: 1024px) 100vw, 50vw"
                        priority
                      />
                    </div>
                  </div>
                </motion.div>
              </AnimatePresence>
            </div>
          </motion.div>

          {/* CTA Button */}
          <div className="text-center mt-12">
            <Link 
              href="/register/worker"
              className="inline-flex items-center justify-center h-11 px-8 font-medium text-white bg-gray-900 hover:bg-gray-800 transition-colors rounded-md"
            >
              Începe-ți Călătoria de Pro Meseriaș
              <ArrowRight className="w-5 h-5 ml-2" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}